import secureStorage from "../utils/webStorage";
import Vue from "vue";
const mm_flow_id = () => {
  let version = 'v1';
  let firstroute =  window.location.href.toString().split("?")[1];
  if(firstroute) {
      let queryParamsArray = firstroute?.split("&")
      if(queryParamsArray) {
      queryParamsArray.forEach(query => {
          let testquery = query.split("=");
          if(testquery.includes('mm_flow_id')) {
             version = testquery[1];
          }
         
      });
    } else {
      version='v1';
    }
  }
  return version;
};
const getForms = async () => {
  try {
    const value = await secureStorage.getItem(mm_flow_id()+"forms");
    return value;
  } catch (err) {
    return [];
  }
};

const updateLocalStorageArray = (state, value) => {
  let forms;
  getForms()
    .then((res) => {
      forms = res;
    })
    .then(() => {
      forms = forms ? forms : [];
      // Add new data to localStorage Array
      if (forms.length) {
        forms[forms.length-1] = value;
      } else {
        forms.push(value);
      }

      // Save back to localStorage
      secureStorage.setItem(mm_flow_id()+"forms", forms);
    });
};

const updateLocalStorageOtherArray = (state, value) => {
  // Save back to localStorage
  secureStorage.setItem(mm_flow_id()+"otherAnswer", value);
};

const updateStepInLocalStorage = (value) => {
  secureStorage.setItem(mm_flow_id()+"getStep", value);
};

const deleteStateData = (state) => {
  state[mm_flow_id()+'answers'] = [];
};
const deleteLocalStorageArray = () => {
  secureStorage.removeItem(mm_flow_id()+"forms");
};

const deleteLocalAdvisorsProfile = () => {
  secureStorage.removeItem(mm_flow_id()+"getAdvisorsProfile");

}

const deleteStepInLocalStorage = () => {
  secureStorage.setItem(mm_flow_id()+"getStep", 0);
};

const updateStateAnswer = (state, value) => {
  Vue.set(state[mm_flow_id()+'answers'], state[mm_flow_id()+'step'], value);
};

const updateStateOtherAnswer = (state, value) => {
  state[mm_flow_id()+'otherAnswer'] = value;
};

const updateAdvisorsProfile = (value) => {
  secureStorage.setItem(mm_flow_id()+"getAdvisorsProfile", value);
}
const updateDebugxData = (value) => {
  secureStorage.setItem(mm_flow_id()+"getDebugxData", value);
}
const updateAdvisorsPageStatus = (value) => {
  secureStorage.setItem(mm_flow_id()+"getAdvisorsPageStatus", value);
}



const mutations = {
  NEXT_STEP(state, value) {
    state[mm_flow_id()+'step'] = value;
    updateStepInLocalStorage(value);
  },
  PREVIOUS_STEP(state, value) {
    state[mm_flow_id()+'step'] = value;
    updateStepInLocalStorage(value);
  },
  UPDATE_OR_ADD_ANSWER(state, value) {
    updateStateAnswer(state, value);
    updateLocalStorageArray(state, value);
  },
  UPDATE_OR_ADD_OTHER_ANSWER(state, value) {
    updateStateOtherAnswer(state, value);
    updateLocalStorageOtherArray(state, value);
  },
  DELETE_DATA(state) {
    state[mm_flow_id()+'step'] = 0;
    state[mm_flow_id()+'advisorsProfile'] = [];
    if (state[mm_flow_id()+'isAdvisorPage']) {
      state[mm_flow_id()+'isAdvisorPage'] = false;
    }
    deleteStateData(state);
    deleteLocalStorageArray();
    deleteStepInLocalStorage();
    deleteLocalAdvisorsProfile();
    state[mm_flow_id()+'debugxData'] = [];
    updateDebugxData([])
    secureStorage.setItem(mm_flow_id()+"getAdvisorsPageStatus", false);
  },
  IS_ADVISOR_PROFILE_OPEN(state) {
    state[mm_flow_id()+'isAdvisorProfileOpen'] = !state[mm_flow_id()+'isAdvisorProfileOpen'];
    // updateAdvisorsProfile(state.isAdvisorProfileOpen);
  },
  ADVISORS_PROFILE(state, value) {
    state[mm_flow_id()+'advisorsProfile'] = value;
    updateAdvisorsProfile(value)
  },
  FINAL_PAYLOAD(state, value) {
    state[mm_flow_id()+'finalPayload'] = value;
  },
  DEBUGX_DATA(state, value) {
    state[mm_flow_id()+'debugxData'] = value;
    updateDebugxData(value)
  },
  VISITOR_JOB_ID(state, value) {
    state[mm_flow_id()+'visitorJobId'] = value;
    secureStorage.setItem(mm_flow_id()+"visitorJobId", value);
  },
  ADVISOR_PAGE_STATUS(state) {
    state[mm_flow_id()+'isAdvisorPage'] = !state[mm_flow_id()+'isAdvisorPage'];
    updateAdvisorsPageStatus(state[mm_flow_id()+'isAdvisorPage'])
  },
  MATCHMAKING_VERSION(state, value) {
    state[mm_flow_id()+'matchmakingVersion'] = value;
  },
  SET_QUESTION_BANK(state,value) {
    state[mm_flow_id()+'questionsBank']= value;
  },
  SET_START_OVER(state,value) {
    state[mm_flow_id()+'disableStartOver']= value;
  },
};

export default mutations;
