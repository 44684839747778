import { API } from "aws-amplify";
const singleCloumnMobile = (values) => {
  let returnFlag = false;
  values?.forEach(query => {
    if (query.value.length > 15) {
      returnFlag = true;
    }
  });
  return returnFlag;
};
const actions = {
  // steps
  nextStep({ commit }, number) {
    commit("NEXT_STEP", number);
  },

  prevStep({ commit }, number) {
    commit("PREVIOUS_STEP", number);
  },
  // Answers
  updateAnswer({ commit }, value) {
    commit("UPDATE_OR_ADD_ANSWER", value);
  },
  updateOtherAnswer({ commit }, value) {
    commit("UPDATE_OR_ADD_OTHER_ANSWER", value);
  },
  deleteData({ commit }) {
    commit("DELETE_DATA");
  },
  toggleIsAdvisorProfileOpen({ commit }) {
    commit("IS_ADVISOR_PROFILE_OPEN");
  },
  changeAdvisorPage({ commit }) {
    commit("ADVISOR_PAGE_STATUS");
  },
  disableStartOverAction ({commit}, value ){
    commit('SET_START_OVER', value);
  },
  async getAdvisor({ getters, commit }) {
    let query_str = window.location.search;

    // let query_param = this.$router.currentRoute.query;

    const data = getters.apiPayload;
    const {zipCode, profession, saving, additionalDetail, selected, age, income,  } =
      data;
    let endpoint;
    if (query_str) {
      endpoint = `/advisor_matchmaking${query_str}`;
    } else {
      endpoint = `/advisor_matchmaking`;
    }
    return new Promise((resolve, reject) => {
      // Do something here... lets say, a http call using vue-resource
      API.post("MatchMaking", endpoint, {
        body: {
          zip_code: zipCode,
          investable_assets: saving,
          occupation: profession,
          need_for_advisor: selected,
          additional_detail: additionalDetail,
          age: age,
          income: income,

        },
        response: true,
      }).then(
        (response) => {
          commit("VISITOR_JOB_ID", response.data.job_id);
          commit("ADVISORS_PROFILE", response.data.top_advisor_matches);
          // http success, call the mutator and change something in state
          resolve(response); // Let the calling function know that http is done. You may send some data back
        },
        (error) => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        }
      );
    });
  },
  async putSubmitUserForm({ getters}) {
    // let query_param = this.$router.currentRoute.query;
    const data = getters.apiPayload;
    const { firstName, lastName, phone ,email } =
      data;
    let job_id = getters.getVisitorJobId;

   const endpoint = `/advisor_matchmaking/jobs/${job_id}`;

    return new Promise((resolve, reject) => {
      // Do something here... lets say, a http call using vue-resource
      API.put("MatchMaking", endpoint, {
        body: {
          email:email,
          firstname: firstName,
          lastname: lastName,
          phone: Number(phone),

        },
        response: true,
      }).then(
        (response) => {

          // http success, call the mutator and change something in state
          resolve(response); // Let the calling function know that http is done. You may send some data back
        },
        (error) => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        }
      );
    });    
  },
  async postUserDetail({ getters, commit }) {
    let query_str = window.location.search;

    // let query_param = this.$router.currentRoute.query;

    const data = getters.apiPayload;
    const { firstName, lastName, email, phone, zipCode, profession, saving, additionalDetail, selected, age, income, } =
      data;
    let endpoint;
    if (query_str) {
      endpoint = `/advisor_matchmaking${query_str}`;
    } else {
      endpoint = `/advisor_matchmaking`;
    }
    return new Promise((resolve, reject) => {
      // Do something here... lets say, a http call using vue-resource
      API.post("MatchMaking", endpoint, {
        body: {
          firstname: firstName,
          lastname: lastName,
          email: email,
          phone: Number(phone),
          zip_code: zipCode,
          investable_assets: saving,
          occupation: profession,
          need_for_advisor: selected,
          additional_detail: additionalDetail,
          age: age,
          income: income,
        },
        response: true,
      }).then(
        (response) => {
          commit("ADVISORS_PROFILE", response.data.top_matches);
          if(response.data.debugx)
          commit("DEBUGX_DATA", response.data.debugx);
          // commit("ADVISOR_PAGE_STATUS");
          // http success, call the mutator and change something in state
          resolve(response); // Let the calling function know that http is done. You may send some data back
        },
        (error) => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        }
      );
    });
  },
  async postUserDetailv2({ getters, commit }) {
    let query_str = window.location.search;
    // let query_param = this.$router.currentRoute.query;
    const data = getters.apiPayload;
    const questions = getters.getQuestions;
    const otherText = getters.getotherAnswer;
    let finalPayload = {};
    questions.forEach(question => {
      let tmpdataId;
      if(question.type==='single-select' || question.type==='multi-select' ||  question.type==='multi-select-0' || question.type==='order-select' || question.type==='single-select-other') {
        tmpdataId =(question.type==='single-select')?"":[];
        question.values.forEach(questionval => {
        if( question.type==='single-select' && question.display_type==='buttons' && questionval.value === data[question.display_id] ){
          tmpdataId = questionval.id;
        }
        else if( question.type==='single-select' &&  questionval.value === data[question.display_id]?.value &&  question.display_type==='drop-down'){
          tmpdataId = questionval.id;
        }
        else if( question.type==='single-select-other' &&  questionval.value === data[question.display_id]?.value &&  question.display_type==='drop-down'){
          tmpdataId = questionval.id;
        } 
        else if((question.type==='multi-select' || question.type==='multi-select-0' ) && data[question.display_id]?.includes(questionval.value) ){
          tmpdataId.push(questionval.id);
        } 
        })
        if( question.type==='order-select') {
          data[question.display_id].forEach((answersval) => {
               question.values.forEach(questionval => {
                  if (answersval === questionval.value) {
                    tmpdataId.push(questionval.id);
                  }
                });
          });
        }
      } else {
        tmpdataId = data[question.display_id];
      }
      if (otherText != "" && question.type==='single-select-other' ) {
        finalPayload[question.display_id] = { type: question.type, value: tmpdataId, other: otherText }
      } else {
        finalPayload[question.display_id] = { type: question.type, value:tmpdataId }
      }
    });
    const { firstName, lastName, email, phone } = data;
    finalPayload['contact_detail'] = {
      type:'form',
      value:{
          firstname: firstName,
          lastname: lastName,
          email: email,
          phone: Number(phone)
      }
    };
    if(getters.getdebugx!=''){
      finalPayload['debugx']=true
    }
    commit("FINAL_PAYLOAD", finalPayload);
    let endpoint;
    if (query_str) {
      endpoint = `/advisor_matchmaking/v2${query_str}`;
    } else {
      endpoint = `/advisor_matchmaking/v2`;
    }
     // endpoint = `/advisor_matchmaking/v2`;
    
    return new Promise((resolve, reject) => {
      // Do something here... lets say, a http call using vue-resource
      API.post("MatchMaking", endpoint, {
        body: finalPayload,
        response: true,
      }).then(
        (response) => {
          commit("ADVISORS_PROFILE", response.data.top_matches);
          if( response.data.debugx)
          commit("DEBUGX_DATA", response.data.debugx);
          // commit("ADVISOR_PAGE_STATUS");
          // http success, call the mutator and change something in state
          resolve(response); // Let the calling function know that http is done. You may send some data back
        },
        (error) => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        }
      );
    });
  },
  async checkZipCode({ getters }, payload) {
    console.log(getters);

    return new Promise((resolve, reject) => {
      API.get("MatchMaking", `/check_zip/${payload}`, {
        response: true,
      }).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async loadQuestionBank({ getters , commit}) {
    console.log(getters);

    let query_param = window.location.href.toString().split("?")[1];
    return new Promise((resolve, reject) => {
      API.get("MatchMaking", `/advisor_matchmaking/v2?${query_param}`, {
        response: true,
      }).then(
        (response) => {
          resolve(response);
          response.data?.forEach(question => {
            if(response.data?.values)
              question['singleRowMobile'] = singleCloumnMobile(question.values)
            });
          commit("SET_QUESTION_BANK", response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
};

export default actions;
