const mm_flow_id = () => {
  let version = 'v1';
  let firstroute =  window.location.href.toString().split("?")[1];
  if(firstroute) {
      let queryParamsArray = firstroute?.split("&")
      if(queryParamsArray) {
        queryParamsArray.forEach(query => {
          let testquery = query.split("=");
          if(testquery.includes('mm_flow_id')) {
            version = testquery[1];
          }  
        });
    } else {
        version='v1';
    }
  }
  return version;
};
const getters = {
  currentStep: (state) => {
    return state[mm_flow_id()+'step'];
  },
  getAnswers: (state) => {
    if (state[mm_flow_id()+'answers']) {
      return state[mm_flow_id()+'answers'][state[mm_flow_id()+'answers'].length - 1];
    }
    return null;
  },
  getAnswersList:(state) => {
    return state[mm_flow_id()+'answers'];
  },
  apiPayload: (state) => {
    if (state[mm_flow_id()+'answers']) {
      return {
        ...state[mm_flow_id()+'answers'][0],
        ...state[mm_flow_id()+'answers'][1],
        ...state[mm_flow_id()+'answers'][2],
        ...state[mm_flow_id()+'answers'][3],
        ...state[mm_flow_id()+'answers'][4],
        ...state[mm_flow_id()+'answers'][5],
        ...state[mm_flow_id()+'answers'][6],
        ...state[mm_flow_id()+'answers'][7],
        ...state[mm_flow_id()+'answers'][8],
        ...state[mm_flow_id()+'answers'][9],
        ...state[mm_flow_id()+'answers'][10],
      };
    }
    return null
  },
  getAdvisorsProfile: (state) => {
    if (state[mm_flow_id()+'advisorsProfile']) {
      return state[mm_flow_id()+'advisorsProfile']
    }
    return []
  },
  getDebugxData: (state) => {
    if (state[mm_flow_id()+'debugxData']) {
      return state[mm_flow_id()+'debugxData']
    }
    return []
  },
  getVisitorJobId: (state) => {
    if (state[mm_flow_id()+'visitorJobId']) {
      return state[mm_flow_id()+'visitorJobId']
    }
    return null
  },
  getIsAdvisorProfileOpen: (state) => {
    return state[mm_flow_id()+'isAdvisorProfileOpen']
  },
  getAdvisorStatus: (state) => {
    return state[mm_flow_id()+'isAdvisorPage']
  },
  getScreenType: () => {
    return window.innerWidth > 768
      ? "desktop"
      : window.innerWidth > 500
        ? "tab"
        : "mobile";
  },
  getmatchmakingVersion: (state) => {
    return state[mm_flow_id()+'matchmakingVersion'];
  },
  getQuestions: (state) => {
    return state[mm_flow_id()+'questionsBank']
  },
  getUserId:(state)=> {
    return state[mm_flow_id()+'userId']
  },
  getdisableStartOver:(state)=> {
    return state[mm_flow_id()+'disableStartOver']
  },
  getdebugx:(state)=> {
    return state[mm_flow_id()+'debugx']
  },
  getotherAnswer:(state)=> {
    return state[mm_flow_id()+'otherAnswer']
  },
  getfinalPayload:(state)=> {
    return state[mm_flow_id()+'finalPayload']
  },
  get_mm_flow_id:()=> {
    return mm_flow_id()
  }
};

export default getters;
