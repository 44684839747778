import secureStorage from "../utils/webStorage";
import { v4 as uuidv4 } from 'uuid';

const getForms = () => {
  return secureStorage.getItem(mm_flow_id()+"forms");
};

const getStep = () => {
  return secureStorage.getItem(mm_flow_id()+"getStep") ? secureStorage.getItem(mm_flow_id()+"getStep") : 0
};

const getotherAnswer = () => {
  return secureStorage.getItem(mm_flow_id()+"otherAnswer") ? secureStorage.getItem(mm_flow_id()+"otherAnswer") : ""
};

const mm_flow_id = () => {
  let firstroute =  window.location.href.toString().split("?")[1];
  let version ='v1';
  if(firstroute) {
      let queryParamsArray = firstroute?.split("&")
      if(queryParamsArray) {
      queryParamsArray.forEach(query => {
          let testquery = query.split("=");
          if(testquery.includes('mm_flow_id')) {
            version=testquery[1]
          }
         
      });
    } else {
      version = 'v1';
    }
  }
  return version
};
const getAdvisorsProfile = () => {
  return secureStorage.getItem(mm_flow_id()+"getAdvisorsProfile") ? secureStorage.getItem(mm_flow_id()+"getAdvisorsProfile") : []
};
const getDebugxData = () => {
  return secureStorage.getItem(mm_flow_id()+"getDebugxData") ? secureStorage.getItem(mm_flow_id()+"getDebugxData") : []
};
const getAdvisorsPageStatus = () => {
  return secureStorage.getItem(mm_flow_id()+"getAdvisorsPageStatus") ? true : false
};


const getUserId = () => {
  if(secureStorage.getItem(mm_flow_id()+"userId")){
    return secureStorage.getItem(mm_flow_id()+"userId")
  }
  else {
    const userId = uuidv4().toString()
    secureStorage.setItem(mm_flow_id()+"userId",userId)
    return userId
  }
}
const getVisitorJobId = () => {
  return secureStorage.getItem(mm_flow_id()+"visitorJobId") ? secureStorage.getItem(mm_flow_id()+"visitorJobId") : null;
}

var forms = getForms()

const getAnswer = () => {
  let answerInfo = [];
  if (forms) {
    answerInfo = forms;
  }
  return answerInfo;
};
const debugx= () => {
  let version = '';
  let firstroute =  window.location.href.toString().split("?")[1];
      if(firstroute) {
        let queryParamsArray = firstroute?.split("&")
        if(queryParamsArray) {
          queryParamsArray.forEach(query => {
            let testquery = query.split("=");
            if(testquery.includes('debugx')) {
              version = testquery[1];
            }  
          });
      } else {
          version='';
      }
    }
  return version;
};
let stateobj={};
stateobj[mm_flow_id()+'step'] = getStep();
stateobj[mm_flow_id()+'answers'] = getAnswer();
stateobj[mm_flow_id()+'advisorsProfile'] = getAdvisorsProfile();
stateobj[mm_flow_id()+'debugxData'] = getDebugxData();
stateobj[mm_flow_id()+'visitorJobId'] = getVisitorJobId();
stateobj[mm_flow_id()+'userId'] = getUserId();
stateobj[mm_flow_id()+'isAdvisorProfileOpen'] = false;
stateobj[mm_flow_id()+'isAdvisorPage'] = getAdvisorsPageStatus();
stateobj[mm_flow_id()+'matchmakingVersion'] = 0;
stateobj[mm_flow_id()+'questionsBank'] = {};
stateobj[mm_flow_id()+'disableStartOver'] = false;
stateobj[mm_flow_id()+'debugx'] = debugx();
stateobj[mm_flow_id()+'otherAnswer'] = getotherAnswer();
const state = () => (stateobj);

export default state;